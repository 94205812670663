<!-- 资讯动态详情 -->
<template>
  <div class="wrapper pageBox">
    <!-- 顶部banner -->
    <div class="banner">
      <img src="@/assets/img/banner4.png">
      <div class="bannerText">
        <div class="title">{{ parentObj.name }}</div>
        <div class="btn">行业资讯</div>
      </div>
    </div>
    <!-- 内容区域 -->
    <div class="connect">
      <div class="back">资讯动态 > <span class="parent-title" @click="clickParent(parentObj)">{{ parentObj.name }}</span> > <span class="article-title">{{ info.title }}</span></div>
      <div class="news-info-box">
        <!-- 左侧文章区域 -->
        <div id="article" class="info-left">
          <div class="conBox">
            <div class="top-info">
              <div class="info-position">{{ info.title }}</div>
            </div>
            <div class="info-content">
              <div class="info-time">
                <img :src="timeImg" alt="">
                {{ info.createDate }}
              </div>
              <div class="content" v-html="info.content" />
            </div>
          </div>
          <div class="preNext">
            <div class="pre item">
              <span v-show="notEmpty(pre)" @click="clickPreNext(pre)">上一篇：{{ pre.title }}</span>
              <span v-show="!notEmpty(pre)" />
            </div>
            <div class="next item">
              <span v-show="notEmpty(next)" @click="clickPreNext(next)">下一篇：{{ next.title }}</span>
              <span v-show="!notEmpty(next)" />
            </div>
          </div>
        </div>
        <!-- 右侧推荐列表 -->
        <div id="recommend" class="info-right" :class="isFixed ? 'fixed' : ''" :style="{'left': left + 'px', 'top': top + 'px'}">
          <div class="recommend">
            <!-- 标题 -->
            <div class="recommend-title">
              热门资讯
              <img src="@/assets/img/fire.png" alt="">
            </div>
            <!-- 列表 -->
            <div class="recommend-list">
              <div v-for="(item, index) in recommendList" :key="index" class="list-item">
                <div class="left-num">{{ index >= 9 ? ''+ (index + 1) : '0' + (index + 1) }}</div>
                <div class="right-title">
                  <div class="title-text" @click="goInfo(item)">{{ item.title }}</div>
                  <div class="title-line" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import titleMixin from '@/mixin/title-mixin.js';
import { getNewsInfo, fetchPreNext, popularArticleList } from '@/service/api';
export default {
  name: 'NewsInfo',
  components: {},
  mixins: [titleMixin],
  conf() {
    return {
      desction: this.info.desction,
      keywords: this.info.keywords
    };
  },
  asyncData({ route, store }) {
    return store.dispatch('getNewsInfo', route.query.id);
  },
  data() {
    return {
      timeImg: require('../../assets/img/time.png'),
      pre: {},
      next: {},
      navIds: null,
      newsInfo: null,
      recommendList: [],
      isFixed: false,
      screenWidth: '',
      screenHeight: '',
      left: '',
      top: ''
    };
  },
  computed: {
    info() {
      return this.newsInfo || this.$store.state.newsInfo;
    },
    parentObj() {
      if (this.$store.state.navIds && this.$store.state.navIds.child) {
        const a = this.$store.state.navIds.child.find((item) => {
          return Number(this.$route.query.navId) === item.id;
        });
        if (a) return a;
        return {};
      }
      return {};
    }
  },
  watch: {
    '$route.query.id': {
      immediate: false,
      handler(val) {
        if (val) {
          this.getInfo();
          this.fetchPreNext();
          this.popularArticleList();
        }
      }
    }
  },
  created() {},
  mounted() {
    this.fetchPreNext();
    this.popularArticleList();
    window.addEventListener('scroll', this.scrollToTop);
    window.addEventListener('resize', this.scrollToTop);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.scrollToTop);
    window.removeEventListener('resize', this.scrollToTop);
  },
  methods: {
    scrollToTop() {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      const ele = document.getElementById('article');
      const ele2 = document.getElementById('recommend');

      const eleTop = ele.getBoundingClientRect().bottom;
      this.left = ele.getBoundingClientRect().left + 1000;
      const ele2H = ele2.getBoundingClientRect().height;
      const top = ele2H - eleTop;
      if (top > 0) {
        this.top = -top;
      } else {
        this.top = 0;
      }
      this.isFixed = scrollTop >= 510;
    },
    getInfo() {
      getNewsInfo(this.$route.query.id).then((res) => {
        this.newsInfo = res.data;
      });
    },
    fetchPreNext() {
      const id = this.$route.query.id;
      fetchPreNext(id).then((res) => {
        const list = res.data;
        const id = Number(this.$route.query.id);
        this.next = this.pre = {};
        list.forEach((item) => {
          if (item.id > id) {
            this.next = item;
          } else {
            this.pre = item;
          }
        });
      });
    },
    clickPreNext(item) {
      this.$router.push({
        path: '/news/info',
        query: {
          id: item.id,
          navId: this.$route.query.navId
        }
      });
    },
    popularArticleList() {
      popularArticleList().then((res) => {
        if (res.data.length > 10) {
          this.recommendList = res.data.slice(0, 10);
        } else {
          this.recommendList = res.data;
        }
      });
    },
    goInfo(item) {
      this.$router.push({
        path: '/news/info',
        query: {
          id: item.id,
          navId: item.navId
        }
      });
    },
    clickParent(obj) {
      this.$router.push({
        path: obj.pathUrl,
        query: {
          id: obj.id
        }
      });
    }
  }
};
</script>

<style lang='scss' scoped>
.banner {
  height: 400px;
  .btn {
    width: 98px !important;
    background: rgba(255, 255, 255, 0) !important;
    border-radius: 5px;
  }
  .title {
    font-size: 40px!important;
    font-weight: 400;
  }
}
.connect {
  width: 100%;
  background: #fafafa;
  padding-bottom: 200px;
  .back {
    cursor: default;
    width: 1280px;
    margin: 0 auto;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    color: #9299A5;
    .article-title {
      color: #6a6e77;
    }
    .parent-title {
      &:hover {
        cursor: pointer;
        font-weight: 700;
        color: #144fd1;
      }
    }
  }
  .news-info-box {
    width: 1280px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .info-left {
      .conBox {
        width: 980px;
        height: calc(100% - 80px);
        margin: 0 auto;
        background-color: #fff;
        overflow: hidden;
        .top-info {
          margin: 63px 140px 18px 140px;
          .info-position {
            font-size: 26px;
            font-weight: 700;
            color: #252b3a;
            text-align: center;
          }
          .info-share {
            display: flex;
            img {
              width: 18px;
              height: 17px;
              margin-left: 8px;
              margin-top: 16px;
              opacity: 0.66;
            }
          }
        }
        .line {
          width: 100%;
          height: 1px;
          background-color: #dee0e5;
        }
        .info-content {
          margin: 22px 106px 18px 106px;
          min-height: 600px;
          .info-time {
            text-align: center;
            font-size: 12px;
            font-weight: 400;
            color: #9299a5;
            margin-bottom: 50px;
            img {
              width: 16px;
              height: 16px;
              margin-right: 5px;
              vertical-align:text-top;
            }
          }
          .content {
            line-height: 24px;
            /deep/img {
              width: auto!important;
              height: auto!important;
            }
          }
        }
      }
      .preNext {
        width: 980px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        line-height: 80px;
        height: 80px;
        background-color: #fff;
        border-top: 1px solid #dee0e5;
        padding: 0 105px;
        .item {
          width: 300px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
          span {
            cursor: pointer;
          }
        }
        .pre {
          text-align: left;
        }
        .next {
          text-align: right;
        }
      }
    }
    .info-right {
      width: 280px;
      background-color: #fff;
      .recommend {
        padding-top: 70px;
        padding-left: 29px;
        .recommend-title {
          font-size: 20px;
          font-weight: 700;
          color: #252b3a;
          line-height: 32px;
          padding-bottom: 30px;
          img {
            width: 15px;
            height: 19px;
            vertical-align:middle;
            margin-bottom: 3px;
            margin-left: 5px;
          }
        }
        .recommend-list {
          .list-item {
            display: flex;
            justify-content: space-between;
            &:nth-child(n+4) {
              .left-num {
                color: #252B3A;
              }
            }
            .left-num {
              font-size: 18px;
              font-weight: 700;
              color: #a82e2e;
            }
            .right-title {
              .title-text {
                cursor: pointer;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                width: 220px;
                padding-right: 32px;
                font-size: 14px;
                font-weight: 400;
                color: #575d6c;
                line-height: 20px;
                height: 40px;
                grid-template: 40px;
              }
              .title-line {
                margin-top: 18px;
                margin-bottom: 18px;
                width: 100%;
                height: 1px;
                background-color: #dee0e5;
              }
            }
            &:last-child {
              .right-title {
                .title-line {
                  background-color: #fff;
                }
              }
            }
          }
        }
      }
    }
    .fixed {
      position: fixed;
      top: 0px;
      z-index: 0;
    }
  }
}
</style>
